import React, { Dispatch, FC, SetStateAction } from 'react'

import {
  ChevronDownIcon,
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import type {
  LearnerActivity,
  LearnerTask,
  LearnerTrackingStatus,
} from 'types/graphql'

import useAnalytics from 'src/lib/hooks/useAnalytics'

interface taskStatus {
  taskId: number
  status: LearnerTrackingStatus
}

interface CourseChapterCardProps {
  chapter: LearnerActivity
  selectedTask: number
  setTask: Dispatch<SetStateAction<LearnerTask>>
  taskStatuses: taskStatus[]
  isSelected: boolean
}

const CourseChapterCard: FC<CourseChapterCardProps> = ({
  chapter,
  setTask,
  selectedTask,
  taskStatuses,
  isSelected,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="pt-2">
      <Accordion
        defaultExpanded
        className={`rounded-md border border-gray-200 drop-shadow-none ${
          isSelected && 'border-1 border-indigo-500'
        }`}
        style={{ boxShadow: 'none' }}
      >
        <AccordionSummary
          className="h-2 hover:bg-indigo-50"
          expandIcon={<ChevronDownIcon className="h-3 w-3 text-gray-900" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ minHeight: '36px', borderBottom: '1px solid #e5e7eb' }}
        >
          <Typography
            variant="caption"
            display="block"
            className="mr-3 text-sm font-medium leading-5 text-gray-900"
          >
            {chapter.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="p-2">
          {chapter.learnerTasks.map((task) => {
            const taskCompleted =
              taskStatuses.find((taskStatus) => taskStatus.taskId === task.id)
                ?.status === ('COMPLETED' as LearnerTrackingStatus)

            return (
              <div
                key={task.id}
                className={`hover: group m-0.5 flex min-h-7 w-full cursor-pointer flex-row items-center justify-between rounded-md py-1 text-gray-700 hover:bg-indigo-50 hover:text-indigo-500 ${
                  selectedTask === task.id ? 'bg-indigo-50 text-indigo-500' : ''
                }`}
                onClick={() => {
                  setTask(task)
                  trackEvent('Learner', 'Clicked Task', {
                    taskName: task.name,
                  })
                }}
                onKeyUp={() => {}}
                tabIndex={0}
                role="button"
              >
                <div className="flex w-4/5 flex-row">
                  <div>
                    {task.video ? (
                      <VideoCameraIcon
                        className={'mx-2 my-0.5 h-4 w-4 text-gray-400'}
                      />
                    ) : task.quiz?.length > 0 ? (
                      <PuzzlePieceIcon className="mx-2 my-0.5 h-4 w-4 text-gray-400" />
                    ) : task.processMapId ? (
                      <MapIcon className="mx-2 my-0.5 h-4 w-4 text-gray-400" />
                    ) : task.embedURL ? (
                      <CodeBracketIcon className="mx-2 my-0.5 h-4 w-4 text-gray-400" />
                    ) : (
                      <DocumentTextIcon className="mx-2 my-0.5 h-4 w-4 text-gray-400" />
                    )}
                  </div>
                  <Typography
                    id={`learner-task-name-${task.name
                      .trim()
                      .replace(/ /g, '-')}`}
                    className={`text-sm font-normal leading-5 text-gray-700 group-hover:text-indigo-500 ${
                      selectedTask === task.id ? 'text-indigo-500' : ''
                    }`}
                    variant="caption"
                    display="div"
                  >
                    {task.name}
                  </Typography>
                </div>
                <div className="h-6 min-h-4 w-6 min-w-4">
                  <CheckCircleIcon
                    className={`${
                      taskCompleted ? 'text-green-500' : 'text-gray-100'
                    } h-full w-full`}
                    data-testid={`task-completed-${taskCompleted}-${task.id}`}
                  />
                </div>
              </div>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
export default CourseChapterCard
